.App {
  display: flex;
  /* border: 1px solid fuchsia; */
  height: 100vh;
  position: relative;
  flex-direction: row-reverse;
}

.left-pane {
  flex-basis: 75px;
  max-width: 75px;
  position: relative;
  z-index: 1000;
  /* background-color: rgba(0, 0, 0, 0.5); */
  margin-right: 6px;
}

.logo-section {
  /* border: 1px solid rebeccapurple; */
  font-size: 2.4rem;
  position: absolute;
  top: 6px;
  left: -55px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2);

  z-index: 2000;
  padding: 8px;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.footer {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  flex-grow: 1;
  justify-content: space-around;
  padding-left: 16px;
  border-radius: 20px;
  font-size: 2.4rem;
  margin-bottom: 75px;
  margin-right: 300px;
  margin-left: -10px;
  margin-top: -40px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.f-body {
  font-size: 0.8rem;
}

.right-pane {
  /* border: 1px solid red; */

  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  z-index: 1000;
  position: relative;
  padding-top: 8px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 10%,
      rgba(250, 250, 250, 1),
      rgba(250, 250, 250, 1),
      rgba(250, 250, 250, 1),
      rgba(250, 250, 250, 1),
      rgba(250, 250, 250, 1),
      rgba(250, 250, 250, 1),
      rgba(250, 250, 250, 1),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1),

      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1),
      rgb(168, 213, 255, 0.3),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.0))
}

.vav {
  color: white;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 1),


      rgba(0, 0, 0, 0))
}

.pane-section-left {
  /* border: 1px solid turquoise; */
  flex-basis: 25%;
}

.pane-section-right {
  /* border: 1px solid red; */
  flex-basis: 37.5%;
}

.pane-section {
  display: flex;
  font-size: 3.5rem;
  font-weight: 200;

  /* border: 1px solid blue; */
  align-items: center;
}

.illus {
  /* border: 1px solid gray; */
  max-width: 100%;
  object-fit: contain;
  filter: grayscale(60%);
}

.pane-alt {
  /* border: 1px solid teal; */
  flex-direction: row-reverse;
}

.bg {
  height: 100vh;
  object-fit: cover;
  z-index: 0;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  filter: grayscale(100%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}